import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const CellAvatar = ({ value }) => {
  return (
    <AvatarGroup max={4} className="grid-avatar">
      {value?.map((value, index) => {
        return (
          <Avatar alt={value} src="/static/images/avatar/1.jpg" key={index} />
        );
      })}
    </AvatarGroup>
  );
};

export default CellAvatar;
