import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles, setRolesToState } from 'store/slices/roles/rolesSlice';
import RoleEngine from 'app/role-engine';
import storage from 'services/utils/storage';
import { getCookie } from 'services/utils/generic.methods';
import { toast } from 'react-toastify';
import { toastMsg } from 'services/utils/variables';
import { logout } from 'store/slices/auth/authSlice';
import { globalLogout } from 'services/utils/generic-logout-method';
import { getCompanyConfig } from 'store/slices/general/generalSlice';
import { Enums } from 'services/dropdown-enums';
const ProtectedRoute = ({ path, component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(storage.getItem('Profile'));
  const isAuthenticated = !!getCookie('Auth');
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.['host'];

  const logoutHandler = () => {
    toast.error(toastMsg.errors.inValidAuthToken);
    globalLogout();
  };

  const { roles } = useSelector(selectRoles);
  const { voiceEnabled } = useSelector(getCompanyConfig);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      const { CompanyRelationship, UserType, UserRoles } = user;
      let voice = voiceEnabled;
      // check voice enabled from host for partner
      if (CompanyRelationship === Enums.Relationship.Partner) {
        let dynamicMeta = getCookie('DynamicMeta')
          ? JSON.parse(getCookie('DynamicMeta'))
          : null;
        voice = dynamicMeta?.voiceOnboardingEnabled;
      }
      const updatedUser = { ...user, voiceEnabled: voice }; 
      if (!roles || (roles && !Object.keys(roles).length)) {
        const data = RoleEngine(CompanyRelationship, UserType, UserRoles, updatedUser );
        dispatch(setRolesToState(data));
      }
    }
    if (!isAuthenticated && user) {
      logoutHandler();
    }
  }, [roles, user, isAuthenticated , voiceEnabled]);

  useEffect(() => {
    if (isAuthenticated && !user) {
      dispatch(logout());
    }
  }, [isAuthenticated, user, dispatch]);

  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        return isAuthenticated && user && roles && Object.keys(roles).length ? (
          <Component {...props} />
        ) : !isAuthenticated ? (
          <Redirect
            to={{
              pathname: _hostKey ? `/login/${_hostKey}` : '/login',
              state: { from: rest.location }
            }}
          />
        ) : null;
      }}
    />
  );
};

export default ProtectedRoute;
