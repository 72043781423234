import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { CompanyAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: {}
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setCompanyToState: (state, action) => {
      state.company = action.payload.data;
    }
  }
});

export const { setCompanyToState } = companySlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getCompanyAsync = payload => (dispatch, getState) => {
  let company = getState()?.company?.company;
  if (
    // check if company object has atleast a value
    (company && !Object.keys(company).length) ||
    // or the id of the company is different from the payload
    +company.id !== +payload.params.id ||
    // or forcely receiving the refresh true property
    payload.params.isRefresh
  ) {
    // need to add id of specific company to get
    let companyAPI = CompanyAPI.get + '/' + payload.params.id;
    return ApiServices.getWithParams(companyAPI, payload)
      .then(async ({ data }) => {
        if (data?.success && data?.data) {
          await dispatch(setCompanyToState(data));
          return Promise.resolve(data);
        }
        return Promise.reject('rejected');
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};
/* called a selector and allows to select values from the state */
export const selectCompany = state => {
  return state.company;
};

export const getCompanyById = id => {
  let companyAPI = CompanyAPI.get + '/' + id;
  return ApiServices.get(companyAPI);
};
export const getCompanyDynamicConfig = () => {
  let companyAPI = CompanyAPI.getCompanyConfig;
  return ApiServices.get(companyAPI);
};
export const getCompaniesAppointmentStatusFlag = () => {
  let companyAPI = CompanyAPI.hasAppointmentStatusEnable;
  return ApiServices.get(companyAPI);
};
export const getCompanyApperanceById = id => {
  let companyAPI = CompanyAPI.get + '/' + id;
  return ApiServices.get(companyAPI);
};
export const getVoiceRepresentatives = payload => {
  let url = CompanyAPI.getVoiceReps(payload.companyId);
  return ApiServices.getWithParams(url, { params: payload });
};

export const getVoiceRepById = payload => {
  let url = CompanyAPI.getVoiceRep(payload.id);
  return ApiServices.getWithParams(url, { params: payload });
};

export const updateVoiceRepresentatives = payload => {
  let url = CompanyAPI.saveVoiceReps(payload.companyId);
  return ApiServices.put(url, payload);
};

export const deleteVoiceRepresentatives = payload => {
  let url = CompanyAPI.deleteVoiceRep(payload.companyId, payload.id);
  return ApiServices.post(url, { params: payload });
};

export const updateVoiceData = payload => {
  let url = CompanyAPI.updateVoice(payload.companyId);
  return ApiServices.put(url, payload);
};

export const resendCompanyRepsEmail = payload => {
  let url = CompanyAPI.resendEmail(payload.id);
  return ApiServices.post(url, { params: payload });
};

export const getCompanyReps = payload => {
  let url = CompanyAPI.getSalesReps(payload.companyId);
  return ApiServices.getWithParams(url, { params: payload });
};

export const updateCompanyReps = (companyId, payload) => {
  let url = CompanyAPI.saveSalesReps(companyId);
  return ApiServices.put(url, payload);
};

export const deleteCompanyReps = payload => {
  let url = CompanyAPI.deleteSalesRep(payload.id, payload.companyId);
  return ApiServices.post(url, { params: payload });
};

export const getSchedulingPages = payload => {
  let url = CompanyAPI.schedulingPages(payload.companyId);
  return ApiServices.getWithParams(url, { params: payload });
};

export const updateSchedulingPage = (companyId, payload) => {
  let url = CompanyAPI.saveSchedulingPage(companyId);
  return ApiServices.put(url, payload);
};

export const deleteSchedulingPage = payload => {
  let url = CompanyAPI.deleteSchedulingPage(payload.id);
  return ApiServices.post(url, payload);
};

export const updateThirdPartyScheduling = payload => {
  let url = CompanyAPI.saveThirdPartySchedule(payload.companyId);
  return ApiServices.put(url, payload);
};

export const associateSalesReps = (pageId, payload) => {
  let url = CompanyAPI.associateSalesReps(pageId);
  return ApiServices.put(url, payload);
};

export const getAssociatedPages = payload => {
  let url = CompanyAPI.getAssociatedPages(
    payload.companyId,
    payload.salesReptId
  );
  return ApiServices.getWithParams(url, { params: payload });
};

export const isDirtySchedulingPage = id => {
  let url = CompanyAPI.isDirtySchedulingPage(id);
  return ApiServices.post(url);
};
export default companySlice.reducer;
