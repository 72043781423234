import ApiServices from 'api/apexchat.api.services';
import { AppointmentAPI } from 'api/endpoints';

export const getAppointmentMembers = payload => {
  let apiUrl = AppointmentAPI.getAppointmentMembers;
  return ApiServices.appointment_getwithParams(apiUrl, { params: payload });
};

export const getCalendars = payload => {
  let apiUrl = AppointmentAPI.getCalendars;
  return ApiServices.appointment_getwithParams(apiUrl, { params: payload });
};

export const getParticipantsByCalendarId = payload => {
  let apiUrl = AppointmentAPI.getCalendarParticipants;
  return ApiServices.appointment_getwithParams(apiUrl, { params: payload });
};

export const getParticipantsByIds = payload => {
  let apiUrl = AppointmentAPI.getParticipantsByIds;
  return ApiServices.appointment_getwithParams(apiUrl, { params: payload });
};

export const createCalendar = payload => {
  let endpoint = AppointmentAPI.createCalendar;
  return ApiServices.appointment_post(endpoint, payload);
};

export const addParticipant = payload => {
  let endpoint = AppointmentAPI.addParticipant;
  return ApiServices.appointment_post(endpoint, payload);
};
export const sendEmailToParticipant = id => {
  let endpoint = AppointmentAPI.sendEmailToParticipant(id);
  return ApiServices.appointment_get(endpoint);
};

export const addParticipantToCalendar = payload => {
  let endpoint = AppointmentAPI.addParticipantToCalendar;
  return ApiServices.appointment_getwithParams(endpoint, { params: payload });
};
export const removeParticipantFromCalendar = payload => {
  let endpoint = AppointmentAPI.removeParticipantFromCalendar;
  return ApiServices.appointment_getwithParams(endpoint, { params: payload });
};

export const getparticipantOpeningHours = payload => {
  let endpoint = AppointmentAPI.getOpeningHours;
  return ApiServices.appointment_getwithParams(endpoint, { params: payload });
};
export const getTimeZones = payload => {
  let endpoint = AppointmentAPI.getTimeZones;
  return ApiServices.appointment_getwithParams(endpoint, { params: payload });
};
export const saveOpeningHoursToCalendar = payload => {
  let endpoint = AppointmentAPI.saveOpeningHours;
  return ApiServices.appointment_post(endpoint, payload);
};
export const removeOpeningHoursFromCalendar = payload => {
  let endpoint = AppointmentAPI.removeOpeningHours;
  return ApiServices.appointment_getwithParams(endpoint, { params: payload });
};

export const deleteParticipant = id => {
  let endpoint = AppointmentAPI.removeParticipant(id);
  return ApiServices.appointment_get(endpoint);
};

export const deleteCalendar = id => {
  let endpoint = AppointmentAPI.deleteCalender(id);
  return ApiServices.appointment_get(endpoint);
};
